import React from 'react'

const Counter = () => {
  return (
    <div>
       <div className="container mb-5" id="counter">
              <div className="row text-center" id="countcontain">
                  <div className="col-md-2 p-3" id="count">
                    <h2 className="">
                      90K+
                    </h2>
                    <p>
                    Happy Clients
                    </p>
                  </div>
                  <div className="col-md-2 p-3" id="count">
                  <h2 className="">
                    45M+
                    </h2>
                    <p>
                    line of code
                    </p>
                  </div>
                  <div className="col-md-2 p-3 " id="count">
                  <h2 className="">
                    190+
                    </h2>
                    <p>
                    Total Download
                    </p>
                  </div>
                  <div className="col-md-2 py-3 px-1" id="count">
                  <h2 className="">
                    380K
                    </h2>
                    <p>
                    YouTube Subscribers
                    </p>
                  </div>
              </div>
        </div>
    </div>
  )
}

export default Counter
