import React, { useEffect, useState } from 'react'

import axios from "axios";
import { ReactTyped } from 'react-typed';


const baseurl = process.env.REACT_APP_BASE_URL;

const Banner = () => {

  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchCarouselItems = async () => {
      try {
        const response = await axios.get(`${baseurl}api/getAllBanner`);
        console.log("Fetched Data:", response.data);

        setBanner(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchCarouselItems();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (banner.length === 0) {
    return <div>No carousel items found.</div>;
  }

  return (
    <div>
      <div className="carousel-box">
        <div
          id="carouselExampleCaptions"
          className="carousel slide"
          data-bs-ride="carousel"
          data-bs-interval={5000}
        >
          <div className="carousel-inner">
            {banner.map((item, index) => (
              <div
                key={item.id}
                className={`carousel-item ${index === 0 ? 'active' : ''}`}
              >
                <img
                  src={`${baseurl}${item.image}`}
                  className="d-block w-100"
                  alt={`carousel-item-${item.id}`}
                  // height={600}
                  // width={1000}
                />
                <div className="carousel-caption d-md-block">
                  <h1
                    style={{
                      fontWeight: '800',
                      fontSize: 30,
                      fontFamily: 'Poppins, sans-serif',
                      color: '#ffffff',
                    }}
                  >
                    <ReactTyped
                      strings={[item.name]}
                      typeSpeed={190}
                      backSpeed={80}
                      loop
                      tagName="h1"
                    />
                  </h1>
                  <p
                    className="fs-4 text-center d-none d-md-block"
                    style={{ color: '#ffffff' }}
                  >
                    <ReactTyped
                      strings={[item.description]}
                      typeSpeed={200}
                      backSpeed={100}
                      startDelay={2500}
                      loop
                    />
                  </p>
                </div>
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>


    </div>
  )
}

export default Banner
