import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import axios from "axios";
import "aos/dist/aos.css";

const Portfolio = () => {
  // Base URL from environment variable
  const baseurl = process.env.REACT_APP_BASE_URL;
  const imgurl = process.env.REACT_APP_IMG_URL;


  const [projects, setProjects] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    axios
    
      .get(`${baseurl}api/getAllPortfolios`)
      .then((response) => {
        setProjects(response.data);

      })
      .catch((error) => {
        console.error("Error fetching project data:", error);
      });
  }, [baseurl]); 

  return (
    <Layout>
      <div className="position-relative text-center mb-5" id="topimg">

        <img className="curved-image" src={`${imgurl}${projects[0]?.banner}`} alt="image" />
        <div className="overlay">Portfolio</div>
      </div>

      <div className="container-fluid text-center">
        <div>
          <h2 className="mb-3">Successful Projects</h2>
          <p className="mb-3">
            At the forefront of the industry, we're continually innovating and
            delivering cutting-edge solutions to our clients' most complex{" "}
            <br />
            challenges. Our dedication to innovation drives us to develop and
            refine successful projects focused on developing
          </p>

        <div className="container pt-5">
          {projects.map((item, index) => (
            <div className="row align-items-center mb-1 px-3 ngxyhr" key={index}>
              {index % 2 === 0 ? (
                <>
                  {/* Text on the left, Image on the right */}
                  <div className="col-md-6 bod-left rounded mb-2">
                    <h3>{item.name}</h3>
                    <p className="py-3">{item.description}</p>
                  </div>
                  <div className="col-md-6 text-center mb-2">
                    <div className='SAPimagebox mx-auto'>
                      <img
                        src={`${imgurl}${item.inner_image}`}
                        alt="Item"
                        className="img-fluid rounded"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* Image on the left, Text on the right */}
                  <div className="col-md-6 text-center mb-2">
                    <div className='SAPimagebox mx-auto'>
                      <img
                        src={`${imgurl}${item.inner_image}`}
                        alt="Item"
                        className="img-fluid rounded"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 bod-left rounded mb-2">
                    <h3>{item.name}</h3>
                    <p className="py-3">{item.description}</p>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>

        </div>
      </div>
    </Layout>
  );
};

export default Portfolio;
