import React, { useEffect } from "react";
import Layout from "../Components/Layout";
import img1 from "../img/6.png";
import Cardgallery from "../Components/Cardgallery";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <div>
        {/* top section img */}

        <div className="position-relative text-center  mb-5" id="topimg">
          <img className="curved-image " src={img1} alt="image" />
          <div className="overlay">Our Service</div>
        </div>
        <div className="py-5">
          <Cardgallery />
        </div>

        <div className="text-center" id="animated1">
          <h2>Our Working Process</h2>
          <p>
            Our work process is agile and collaborative, emphasizing clear
            communication and continuous{" "}
          </p>
          <p>improvement.</p>

          <div className="container mt-5 mb-5 d-flex justify-content-center">
            <div className="row m-2" id="row">
              <div className="col-md-3 rounded-circle">
                <div className=" rounded-circle" id="icons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="84"
                    height="84"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="#11235a"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    >
                      <path
                        stroke-dasharray="72"
                        stroke-dashoffset="72"
                        d="M12 3h7v18h-14v-18h7Z"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          dur="0.6s"
                          values="72;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="12"
                        stroke-dashoffset="12"
                        stroke-width="1"
                        d="M14.5 3.5v3h-5v-3"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.7s"
                          dur="0.2s"
                          values="12;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="10"
                        stroke-dashoffset="10"
                        d="M9 13l2 2l4 -4"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.9s"
                          dur="0.2s"
                          values="10;0"
                        />
                      </path>
                    </g>
                    <path
                      fill="#11235a"
                      fill-opacity="0"
                      d="M6 4H10V6H14V4H18V20H6V4Z"
                    >
                      <animate
                        fill="freeze"
                        attributeName="fill-opacity"
                        begin="1.2s"
                        dur="0.15s"
                        values="0;0.3"
                      />
                    </path>
                  </svg>
                </div>

                <h4>Planning</h4>
              </div>

              <div className="col-md-3">
                <div className=" rounded-circle" id="icons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="84"
                    height="84"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="#11235a"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    >
                      <path
                        fill="#11235a"
                        fill-opacity="0"
                        stroke-dasharray="64"
                        stroke-dashoffset="64"
                        d="M22 3v14c0 0.55 -0.45 1 -1 1h-14c-0.55 0 -1 -0.45 -1 -1v-14c0 -0.55 0.45 -1 1 -1h14c0.55 0 1 0.45 1 1Z"
                      >
                        <animate
                          fill="freeze"
                          attributeName="fill-opacity"
                          begin="1.9s"
                          dur="0.15s"
                          values="0;0.3"
                        />
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          dur="0.6s"
                          values="64;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="10"
                        stroke-dashoffset="10"
                        d="M10 6h8"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.7s"
                          dur="0.2s"
                          values="10;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="10"
                        stroke-dashoffset="10"
                        d="M10 10h8"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.9s"
                          dur="0.2s"
                          values="10;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="6"
                        stroke-dashoffset="6"
                        d="M10 14h5"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="1.1s"
                          dur="0.2s"
                          values="6;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="36"
                        stroke-dashoffset="36"
                        d="M2 6v15c0 0.55 0.45 1 1 1h15"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="1.4s"
                          dur="0.5s"
                          values="36;0"
                        />
                      </path>
                    </g>
                  </svg>
                </div>
                <h4>Analysis</h4>
              </div>
              <div className="col-md-3">
                <div className=" rounded-circle" id="icons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="84"
                    height="84"
                    viewBox="0 0 24 24"
                  >
                    <path fill="#11235a" d="M10 16h4v0h-4z">
                      <animate
                        fill="freeze"
                        attributeName="d"
                        begin="0.6s"
                        dur="0.2s"
                        values="M10 16h4v0h-4z;M10 16h4v6h-4z"
                      />
                    </path>
                    <g
                      fill="none"
                      stroke="#11235a"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    >
                      <path
                        stroke-dasharray="72"
                        stroke-dashoffset="72"
                        d="M12 17h-10v-14h20v14Z"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          dur="0.6s"
                          values="72;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="4"
                        stroke-dashoffset="4"
                        d="M12 21h3M12 21h-3"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.8s"
                          dur="0.2s"
                          values="4;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="4"
                        stroke-dashoffset="4"
                        d="M6 7h2M6 7v2M18 13h-2M18 13v-2"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="1.1s"
                          dur="0.2s"
                          values="4;0"
                        />
                      </path>
                    </g>
                  </svg>
                </div>

                <h4>Design</h4>
              </div>
              <div className="col-md-3">
                <div className=" rounded-circle" id="icons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="84"
                    height="84"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="#11235a"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    >
                      <path
                        stroke-dasharray="64"
                        stroke-dashoffset="64"
                        d="M13 3l6 6v12h-14v-18h8"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          dur="0.6s"
                          values="64;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="14"
                        stroke-dashoffset="14"
                        stroke-width="1"
                        d="M12.5 3v5.5h6.5"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.7s"
                          dur="0.2s"
                          values="14;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="8"
                        stroke-dashoffset="8"
                        d="M10 13l-2 2l2 2"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="0.9s"
                          dur="0.2s"
                          values="8;0"
                        />
                      </path>
                      <path
                        stroke-dasharray="8"
                        stroke-dashoffset="8"
                        d="M14 13l2 2l-2 2"
                      >
                        <animate
                          fill="freeze"
                          attributeName="stroke-dashoffset"
                          begin="1.1s"
                          dur="0.2s"
                          values="8;0"
                        />
                      </path>
                    </g>
                  </svg>
                </div>

                <h4>Development</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Services;
