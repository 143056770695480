import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";

import Layout from "../Components/Layout";


const baseurl = process.env.REACT_APP_BASE_URL;

const BlogDetail = () => {
    const [blog, setBlog] = useState(null);
    const { id } = useParams();
    

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseurl}api/Blogs/${id}`);
                setBlog(response.data);
            } catch (err) {
                console.error("Error fetching blog:", err);
            }
        };

        fetchData();
    }, []);

    const formatDate = (date) => {
        const newDate = new Date(date);
        return `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()}`;
    };

    return (
        <>
            <Layout>
                <div className="BlogDetail py-3" id="contentbg">
                    <div className="container px-5 border shadow rounded my-3 bg-light" id="contentcontain">
                        <div className="row py-2">
                            {blog ? (
                                <>
                                    <div className="col">
                                        <h3 className="my-5 text-center">{blog.name}</h3>
                                        <div className="d-flex justify-content-center">
                                            <img
                                                src={`${baseurl}${blog.image}`}
                                                className="img-fluid mb-5"
                                                alt={blog.title}
                                            />
                                        </div>
                                        <p>{blog.description}</p>
                                        {/* <p><strong>Published on: {formatDate(blog.date)}</strong></p> */}
                                    </div>
                                </>
                            ) : (
                                <h5>Blog not found</h5>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>

        </>
    );
};

export default BlogDetail;
