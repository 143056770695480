
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css";

import AOS from "aos";
import axios from "axios";
import "aos/dist/aos.css";

const baseurl = process.env.REACT_APP_BASE_URL;
const imgurl = process.env.REACT_APP_IMG_URL;

const SwiperComponent = () => {
  const [slidess, setslides] = useState([]);

  useEffect(() => {
    axios

      .get(`${baseurl}api/getAllPortfolios`)
      .then((response) => {
        setslides(response.data);
      })
      .catch((error) => {
        console.error("Error fetching project data:", error);
      });
    AOS.init({
      duration: 1300,
    });
  }, []);

  return (

    <>
      <div className="container-fluid bg-light rounded mt-2 mb-5">
        <div className="row text-center">
          <h2 className="py-3 text-capitalize my-2" data-aos="fade-up">
            Our Most Popular <br /> Projects
          </h2>
        </div>
        <div className="row my-3">
          <Swiper
            className="my-5"
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true }}
            autoplay={{ delay: 3000, disableOnInteraction: true }}
            spaceBetween={10}
            slidesPerView={3}
            breakpoints={{
              640: {
                slidesPerView: 1, // 1 slide on small screens (mobile)
                spaceBetween: 10, // space between slides
              },
              768: {
                slidesPerView: 2, // 2 slides on medium screens (tablet)
                spaceBetween: 15, // space between slides
              },
              1024: {
                slidesPerView: 3, // 3 slides on large screens (desktop)
                spaceBetween: 20, // space between slides
              },
            }}
            style={{
              width: "100%",
            }}
          >
            {slidess.map((slide) => (
              <SwiperSlide key={slide.id}>
                <div
                  className="rounded p-3"
                  data-aos="fade-left"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100%",
                    background: "#ffffff",
                    padding: "10px",
                  }}
                >
                  <div className="clientImg my-2 rounded d-flex justify-content-center">
                    <img
                      src={`${imgurl}${slide.image}`}
                      className="img-fluid"
                      alt={slide.description}
                      style={{ maxWidth: "80%", borderRadius: "15px", aspectRatio: "3/2", objectFit: "contain" }}
                    />
                  </div>
                  <div>
                    <h6 className="client-text">{slide.name}</h6>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default SwiperComponent;
