import React, { useState, useEffect } from "react";
import Layout from "../Components/Layout";

import Counter from "../Components/Counter";

import axios from 'axios';

const baseurl = process.env.REACT_APP_BASE_URL;
const imgurl = process.env.REACT_APP_IMG_URL;

const About = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(null);
  const [openAccordion, setOpenAccordion] = useState(1);

  const handleToggle = (index) => {
    if (open === index) {
      setOpen(null);
    } else {
      setOpen(index);
    }
  }


  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseurl}api/about`);
        setData(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch data');
        setLoading(false);
      }
    };

    window.scrollTo(0, 0);
    fetchData();
  }, []);


  return (
    <Layout>
      <div>
        <div className="position-relative text-center mb-5" id="topimg">
          <img className="curved-image shadow" src={`${imgurl}${data[0]?.banner}`} alt="image" />
          {/* <div className="overlay">About</div> */}
        </div>

        <h2 className="text-center mb-5 py-5" id="abouth1">About us</h2>

        <div className="container">
          <div className="row pb-4">
 
            {data.map((item, index) => (
              <div className="row" key={index}>
                {index % 2 === 0 ? (
                  <>
                    <div className="col-md-7 d-flex flex-column justify-content-center mb-5">
                      <div>
                        <h5
                          className="p-2 mb-5"
                          style={{ borderLeft: "4px solid #11235A" }}
                        >
                          {item.inner_title}
                        </h5>
                        <h2 className="mb-4">{item.title}</h2>
                        <p>{item.description}</p>
                      </div>
                    </div>
                    <div className="col-md-5 d-flex justify-content-center align-items-center">
                      <div>
                        <img className="img-fluid" src={`${imgurl}${item.image}`} alt="image" />
                      </div>
                    </div>
                  </>
                ) : (
                  <>

                    <div className="col-md-5 d-flex justify-content-center align-items-center">
                      <div>
                        <img className="img-fluid" src={`${imgurl}${item.image}`} alt="image" />
                      </div>
                    </div>
                    <div className="col-md-7 text-end d-flex flex-column justify-content-center">
                      <div>
                        <h5
                          className="p-2 mb-5"
                          style={{ borderRight: "4px solid #11235A" }}
                        >
                          {item.inner_title}
                        </h5>
                        <h2 className="mb-4">{item.title}</h2>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </>
                )}



              </div>

            ))}
          </div>
        </div>

      </div>

      <div className='bg-light bg-gradient py-5'>

        <h2 className="text-center"> FAQ </h2>
        
        <div id="accordion-start">
          <div className="accordion px-5" id="accordionPanelsStayOpenExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className={`accordion-button ${openAccordion === 1 ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => toggleAccordion(1)}
                  aria-expanded={openAccordion === 1}
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  What IT services do you offer?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                className={`accordion-collapse collapse ${openAccordion === 1 ? 'show' : ''}`}
              >
                <div className="accordion-body">
                  <strong> Services we offer:</strong> We offer a wide range of IT services, including managed IT support, cybersecurity, Digital Marketing, software development, Customized Responsive Website & etc.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className={`accordion-button ${openAccordion === 2 ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => toggleAccordion(2)}
                  aria-expanded={openAccordion === 2}
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  Why should we choose your company over others?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                className={`accordion-collapse collapse ${openAccordion === 2 ? 'show' : ''}`}
              >
                <div className="accordion-body">
                  <strong> Collaboration and Integration:</strong> We combine technical expertise, personalized service, and a customer-centric approach to deliver innovative and reliable IT solutions. Our commitment to excellence ensures your success.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className={`accordion-button ${openAccordion === 3 ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => toggleAccordion(3)}
                  aria-expanded={openAccordion === 3}
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  How much do your IT services cost?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                className={`accordion-collapse collapse ${openAccordion === 3 ? 'show' : ''}`}
              >
                <div className="accordion-body">
                  <strong>Service cost:</strong> The cost of our services varies depending on the specific needs and scale of your business. We offer flexible pricing models, including subscription-based, pay-as-you-go, and custom solutions tailored to your requirements.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className={`accordion-button ${openAccordion === 4 ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => toggleAccordion(4)}
                  aria-expanded={openAccordion === 4}
                  aria-controls="panelsStayOpen-collapseFour"
                >
                  How do you handle system updates and patches?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                className={`accordion-collapse collapse ${openAccordion === 4 ? 'show' : ''}`}
              >
                <div className="accordion-body">
                  <strong>Updates:</strong> We manage and automate software updates, patches, and system maintenance to ensure that your systems remain secure and up to date without disrupting your business operations.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className={`accordion-button ${openAccordion === 5 ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => toggleAccordion(5)}
                  aria-expanded={openAccordion === 5}
                  aria-controls="panelsStayOpen-collapseFive"
                >
                 Can you help with software development?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                className={`accordion-collapse collapse ${openAccordion === 5 ? 'show' : ''}`}
              >
                <div className="accordion-body">
                  <strong>Software Development:</strong> Yes, we offer custom software development services, from mobile apps to enterprise solutions, to help meet your unique business needs and improve operational efficiency.
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </Layout >
  );
};

export default About;
