
import React, { useState, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import axios from 'axios';
import Layout from "../Components/Layout";
import { Modal } from 'bootstrap';

const baseurl = process.env.REACT_APP_BASE_URL;
const imgurl = process.env.REACT_APP_IMG_URL;

function Career() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [CapVal, setCapVal] = useState(true);
    const [responseMessage, setResponseMessage] = useState('');
    const [showModal, setShowModal] = useState(false);

    function onChange(val) {
        setCapVal(false);
    }

    const [formData, setFormData] = useState({
        profileName: "",
        name: "",
        email: "",
        contactNo: "",
        companyName: "",
        description: "",
        ccEmail: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Form submitted:", formData);
        try {
            const response = await axios.post(`${baseurl}api/submit`, formData);
            setResponseMessage(response.data.message || "Form submitted successfully!");
            setError(null);

            setFormData({
                name: "",
                email: "",
                contactNo: "",
                message: "",
            });

            setShowModal(false);
        } catch (err) {
            console.error("Error submitting form:", err);
            setError(err.response?.data?.message || "Failed to submit the form");
            setResponseMessage("");
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseurl}api/careers`);
                setData(response.data);
                setLoading(false);
            } catch (error) {
                setError('Failed to fetch data');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const openModal = () => {
        setShowModal(true); // Open modal
    };

    const closeModal = () => {
        setShowModal(false); // Close modal
    };

    // if (loading) {
    //     return <div>

    //     </div>;
    // }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <>
            <Layout>

                <div className='CareerPage'>

                    <div className='container'>
                        <div className='row'>
                            <h2 className='text-center'>CAREER</h2>
                        </div>
                    </div>
                    <div className="container my-2 py-2">
                        <div className="row">
                            <div className="col">
                                <div className="container">
                                    <div className="row d-flex align-items-stretch">
                                        {data.map((job, index) => (
                                            <div className="col-md-4 careercard mb-4" key={index}>
                                                <div className="text-start bg-light text-dark p-2 rounded h-100 d-flex flex-column">
                                                    <h6 className="text-start bold">{job.title}</h6>
                                                    <p className="clamp flex-grow-1 mb-1">{job.description}</p>
                                                    <button
                                                        type="button"
                                                        className="btn sm align-self-start"
                                                        onClick={openModal}
                                                    >
                                                        APPLY NOW
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Modal */}
                    <div className={`modal ${showModal ? 'show' : ''}`} tabIndex="-1" id="exampleModal" style={{ display: showModal ? 'block' : 'none' }}>
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title text-dark">Job Application</h5>
                                    <button type="button" className="btn-close" onClick={closeModal} 
                                    
                                    ></button>
                                </div>
                                <div className="modal-body contact-formm">

                                    <form onSubmit={handleSubmit}>

                                        <label className="text-dark" htmlFor="name">Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Your Name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />

                                        <label className="text-dark" htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Your Email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />

                                        <label htmlFor="contactNo">Contact Number</label>
                                        <input
                                            type="text"
                                            id="contactNo"
                                            name="contactNo"
                                            placeholder="Your contact number"
                                            value={formData.contactNo}
                                            onChange={handleChange}
                                            required
                                        />

                                        <label className="text-dark" htmlFor="message">Message</label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            placeholder="Describe your role or message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            required
                                        ></textarea>
                                        <ReCAPTCHA
                                            sitekey="6Lfq_6YqAAAAAPg_CPhQcgW_-s1eWXkWPmXbKce8"
                                            onChange={onChange}
                                        />
                                        <button
                                            type="submit"
                                            disabled={CapVal}
                                            style={{
                                                backgroundColor: CapVal ? "#000" : "#11235A",
                                            }}
                                            className="btn btn-primary mt-3"
                                        >
                                            Send Message
                                        </button>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    {responseMessage && <p className="text-success mt-3">{responseMessage}</p>}
                                    {error && <p className="text-danger mt-3">{error}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}

export default Career;
