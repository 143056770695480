import React, { useState, useEffect } from "react";
import Footer from './Footer'
import Navbar from "./Navbar";

const baseurl = process.env.REACT_APP_BASE_URL;


const Layout = (props) => {

    const [userData, setUserData] = useState(null);
    const [pageList, setPageList] = useState([]);
  
    useEffect(() => {
      const fetchUserData = async () => {
        try {
          const response = await fetch(`${baseurl}api/getAllservicess`);
          const data = await response.json();
          setUserData(data);

          const res = await fetch(`${baseurl}api/getAllPageBuilders`);
          const datap = await res.json();
          setPageList(datap);

        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
  
      fetchUserData();
    }, []);


  return (
    <div id="Layoutroot">

      <Navbar userData={userData} />
       <main> 
        {props.children}
      </main>
      <Footer userData={userData} pageList={pageList} />

    </div>
  )
}

export default Layout;
