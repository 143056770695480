
import React, { useState, useEffect } from "react";
import logo2 from "../img/vertical.png";
import { NavLink, Link } from "react-router-dom";



const Footer = ({ userData, pageList }) => {


  // if (!pageList || pageList.length === 0) {
  //   return <div>No page list available</div>;
  // }


  return (
    <div>
      <footer className="bd-footer mt-5">

        <div className="container-fluid fotter text-body-secondary">
          <div className="row">
            <div className="col-md-2 text-center p-3 px-3" id="whitefoot">
              <img src={logo2} style={{ width: "10rem" }} alt="Company Logo" />
              <div className="footer-icons">
                <a
                  href="https://www.facebook.com/crimsonssystems/"
                  // href={userData[0]?.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                >
                  <i className="bi bi-facebook" />
                </a>
                <a
                  href="https://x.com/Crimsonssystems"
                  // href={userData.x_com}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Twitter"
                >
                  <i className="bi bi-twitter-x" />
                </a>
                <a
                  href="https://www.linkedin.com/company/101721285/admin/dashboard/"
                  // href={userData.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="LinkedIn"
                >
                  <i className="bi bi-linkedin" />
                </a>
                <a
                  href="https://www.instagram.com/crimsonssystems/"
                  // href={userData.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram"
                >
                  <i class="bi bi-instagram"></i>
                </a>
              </div>
            </div>
            <div className="row col-md-9 p-3">
              <div className="col-6 col-md-4 mb-3" id="footertxt">
                <div className="">
                  <h5 className="mb-3">Quick Links</h5>
                  <ul className="list-unstyled">

                    <li className="mb-3">
                      <NavLink className="nav-link" to="/services">
                        Services
                      </NavLink>
                    </li>
                    <li className="mb-3">
                      <NavLink className="nav-link" to="/Blog">
                        Blog
                      </NavLink>
                    </li>
                    <li className="mb-3">
                      <NavLink className="nav-link" to="/about">
                        About
                      </NavLink>
                    </li>
                    <li className="">
                      <NavLink className="nav-link" to="/Portfolio">
                        Portfolio
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-6 col-md-4" id="footertxt">
                <div>
                  <h5 className="mb-3">Support</h5>

                  <ul className="list-unstyled">
                    {pageList && Array.isArray(pageList) && pageList.length > 0 ? (
                      pageList.map((service) => (
                        <li key={service.id} className="mb-3">
                          <NavLink className="nav-link" to={`/pagebuilder/${service.id}`}>
                            {service.title}
                          </NavLink>
                        </li>
                      ))
                    ) : (
                      <li>Loading...</li>
                    )}
                  </ul>

                </div>
              </div>

              <div className="col-6 col-md-4 flex-grow-1" id="footertxt">
                <div>
                  <h5 className="mb-3">Office Info</h5>
                  <div className='footer'>

                    {userData && userData[0] && (
                      <div>
                        <ul>
                          <li className="mb-3"><i class="bi bi-geo-alt-fill"></i>{" "}{userData[0].address}</li>
                          <li className="mb-3"><i className="bi bi-telephone-fill"></i> {" "}<a href={`tel:${userData[0].contact}`}>{userData[0].contact}</a></li>
                          <li className="mb-3"><i className="bi bi-envelope-at-fill"></i>{" "} <a href={`mailto:${userData[0].email}`}>{userData[0].email}</a></li>

                          {/* <li><i className="bi bi-envelope-at"></i> <a href={`mailto:${settings.email || ' '}`}>{settings.email || 'loading..'}</a></li> */}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </footer>
    </div>
  );
};

export default Footer;
