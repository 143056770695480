
import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../img/horizontal.png";

function Navbar({ userData }) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="Mainbar container-fluid bg-light">
      <div className="row position-relative">
        <div className="col-12 col-md-3 col-sm-3" style={{ display: "flex", alignItems: "center" }}>
          <Link to="/">
            <img className="" src={logo} alt="crimsons systems" />
          </Link>
        </div>
        <div className="navcont col-12 col-sm-9 col-md-9">
          <div className="d-flex flex-column">
            {userData && userData[0] && (
              <div className="gap-4 d-flex py-3 p-5" id="uppernav">
                <span>{userData[0].email}</span>
                <span>{userData[0].contact}</span>
                <span>{userData[0].address}</span>
              </div>
            )}
            <div>
              <nav className="navbar navbar-expand-lg navbar-light ml-2 gap-2">
                <div className="container-fluid">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleNavbar}
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className={`collapse navbar-collapse ${isCollapsed ? "" : "show"}`} id="navbarNav">
                    <ul className="navbar-nav py-1">
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/">
                          Home
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/services">
                          Services
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/Portfolio">
                          Portfolio
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/Blog">
                          Blog
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/about">
                          About
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/Career">
                          Career
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/Contact">
                          Contact
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
