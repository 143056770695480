import React from 'react'
// import {Route, Routes} from "react-router-dom"
import Homepage from './Pages/Homepage'
import Navbar from './Components/Navbar'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Footer from './Components/Footer';
import { Route, Routes } from 'react-router-dom';
import Services from './Pages/Services';
import Blog from './Pages/Blog';
import BlogDetail from './Pages/BlogDetail';
import PageDetail from './Pages/PageDetail';
import About from "./Pages/About";
import Portfolio from './Pages/Portfolio';
import Career from './Pages/Career';
import Contact from './Pages/Contact';


const App = () => {

  
  return (
  <>
   <Routes>
   <Route path="/" element={<Homepage/>}/>
   <Route path="/services" element={<Services/>}/>
   <Route path="/blog" element={<Blog/>}/>
   <Route path="/about" element={<About/>}/>
   <Route path="/portfolio" element={<Portfolio/>}/>
   <Route path="/Blogs/:id" element={<BlogDetail />} />
   <Route path="/pagebuilder/:id" element={<PageDetail />} />
   <Route path="/Contact" element={<Contact />} />
   <Route path="/Career" element={<Career />} />


   </Routes>
  </>
  )
}

export default App;
