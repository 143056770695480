
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";

import Layout from "../Components/Layout";

const baseurl = process.env.REACT_APP_BASE_URL;
const imgurl = process.env.REACT_APP_IMG_URL;

function PageDetail() {

    const [page, setPage] = useState(null);
    const { id } = useParams();


    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseurl}api/pagebuilder/${id}`);
                setPage(response.data);
            } catch (err) {
                console.error("Error fetching blog:", err);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <>
                <Layout>
                    <div className="BlogDetail py-3" id="contentbg">
                        <div className="container px-2 border my-3 bg-light">
                            <div className="row py-2">
                                {page ? (
                                    <>
                                        <div className="col">
                                            <div>
                                                <div className="d-flex justify-content-center">
                                                    <img
                                                        src={`${imgurl}${page.image}`}
                                                        className="img-fluid mb-5"
                                                        alt={page.title}
                                                    />
                                                </div>
                                                <h3 className="my-1">{page.title}</h3>
                                                <p>{page.description}</p>
                                            </div>

                                        </div>
                                    </>
                                ) : (
                                    <h5>Page not found</h5>
                                )}
                            </div>
                        </div>
                    </div>
                </Layout>

            </>
        </div>
    )
}

export default PageDetail;


