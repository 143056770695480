import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import axios from "axios";

const baseurl = process.env.REACT_APP_BASE_URL;

const Cardgallery = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    axios
    
      .get(`${baseurl}api/services`)
      .then((response) => {
        setCards(response.data);
      })
      .catch((error) => {
        console.error("Error fetching cards data:", error);
      });
  }, []);

  return (
    <div className="container-fluid mb-4">
      <div className="row">
        {cards.map((card) => (
          <div className="col-md-6 p-0" key={card.id}>
            <div className="cardBox">
              <div className="card bg-dark text-white" id="card">
                <img src={`${baseurl}${card.image}`} className="card-img" alt="..." />
                <div className="card-img-overlay" id="card-overlay">
                  <h2 className="card-title b-0">{`${card.name}`}</h2>
                </div>
              </div>
            </div>

          </div>
        ))}
      </div>
    </div>
  );
};

export default Cardgallery;
