import React, { useState, useEffect } from "react";
import axios from 'axios';

import Layout from "../Components/Layout";
import Banner from "../Components/Banner";
import Cardgallery from "../Components/Cardgallery";
import abtimg from "../img/office.png";
import SwiperComponent from "../Components/SwiperComponent";
import Counter from "../Components/Counter";
import vid from "../../src/img/video.mp4";

const basurl = process.env.REACT_APP_BASE_URL;


const Homepage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  return (
    <Layout>
      <div className="homepage">
        <Banner />
        <Cardgallery />

        {/* {ABOUT} */}

        <div id="about" className="mt-0">
          <div className="container mb-5">
            <div className="row">
              <div className="col-md-6 p-3">
                <div className="position-relative">
                  <img className="img-fluid" src={abtimg} alt="not load" />

                    <video
                      className="position-absolute bottom-0 end-0 video-overlay vid-wrap"
                      src={vid}
                      muted
                      loop
                      autoPlay
                    ></video>


                </div>
              </div>

              <div className="col-md-6 p-1">
                <h4 className="successhead">Our Story</h4>
                <hr />
                <h1>
                Empowering Innovation with Expert IT Solutions
                </h1>
                {/* <p className="">
                  Join us on our journey of discovery and innovation, where each
                  project showcases our dedication to excellence and pushing
                  boundaries. We introduce fresh ideas, methods, or technologies
                  to tackle challenges creatively
                </p> */}

                <div className="checkbtn mb-3 row">
                  <div className="col-md-6 p-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="red"
                      class="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                    Web Development
                  </div>
                  <div className="col-md-6 p-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="red"
                      class="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                    Mobile App development
                  </div>
                  <div className="col-md-6 p-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="red"
                      class="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                    UI/UX Design
                  </div>
                  <div className="col-md-6 p-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="red"
                      class="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                    Digital Marketing
                  </div>
                  <div className="col-md-6 p-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="red"
                      class="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                    AI ML
                  </div>
                  <div className="col-md-6 p-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      fill="red"
                      class="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                    </svg>
                    Outsourcing
                  </div>
                </div>
                {/* <button
                  className="btn px-4 py-2 rounded-pill fs-6"
                  style={{ background: "#11235A", color: "#ffffff" }}
                  type="button"
                >
                  Explore More <i className="bi align-middle bi-arrow-right"></i>{" "}
                </button> */}
              </div>
            </div>
          </div>
        </div>

        {/* swiper */}

        <SwiperComponent />

        {/* counting numbers */}

        <Counter />
      </div>
    </Layout>
  );
};

export default Homepage;
