
import React, { useState, useEffect } from "react";

import Layout from "../Components/Layout";
import { Link } from 'react-router-dom';

import axios from "axios";


const Blog = () => {
  const baseurl = process.env.REACT_APP_BASE_URL;
  const imgurl = process.env.REACT_APP_IMG_URL;


  const [Blogs, setBlogs] = useState([])


  useEffect(() => {
    window.scrollTo(0, 0);


    axios
      .get(`${baseurl}api/getAllblogs`)
      .then((response) => {
        setBlogs(response.data);
      })
      .catch((error) => {
        console.error("Error fetching project data:", error);
      });

  }, [])


  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 9;


  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = Blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(Blogs.length / blogsPerPage);

  return (
    <Layout>
      <div className="">
        <div className="position-relative text-center mb-5" id="topimg">
          <img className="curved-image " src={`${baseurl}${Blogs[0]?.image2}`} alt="banner" />
          <div className="overlay">Blogs</div>
        </div>

        <h2 className="text-center">Our Blogs</h2>
        <div className="container">
          <div className="row">
            <div className="col py-3">
              <div className="container">

                <div className="row">
                  {currentBlogs.map((blog) => (
                    <div className="col-md-4 mb-3" id="cardcontainer" key={blog.id}>
                      <div
                        className="card mb-3 p-0 d-flex h-100"
                        id="cardcont"
                        style={{ width: "22rem" }}
                      >
                        <img src={`${imgurl}${blog.image2}`} className="cardimg" alt="image" />
                        <div className="card-body">

                          <h5 className="card-title">{blog.name}</h5>

                          <p className="card-text line-clamp border-top">
                            {blog.description}
                          </p>
                          <Link to={`/Blogs/${blog.id}`} className="icon-link gap-1 icon-link-hover">
                            Continue reading
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Pagination */}
                <div className="d-flex justify-content-center mt-4">
                  <nav>
                    <ul className="pagination">
                      {[...Array(totalPages).keys()].map((number) => (
                        <li
                          key={number + 1}
                          className={`page-item ${currentPage === number + 1 ? "active" : ""
                            }`}
                        >
                          <a
                            onClick={() => paginate(number + 1)}
                            className="page-link"
                            href="#!"
                          >
                            {number + 1}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;
