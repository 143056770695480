import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import Layout from "../Components/Layout";
import axios from "axios";

const baseurl = process.env.REACT_APP_BASE_URL;
const imgurl = process.env.REACT_APP_IMG_URL;


function Contact() {
    const [data, setData] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [CapVal, setCapVal] = useState(true);



    const [formData, setFormData] = useState({
        name: "",
        email: "",
        contactNo: "",
        message: "",
    });

    function onChange(val) {
        setCapVal(false)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseurl}api/getAllservicess`);
                setData(response.data);
            } catch (err) {
                setError(err.message || "Failed to fetch data");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // const response = await axios.post(`${baseurl}/contactUS`, formData);
            const response = await axios.post(`${baseurl}api/submit`, formData);
            setResponseMessage(response.data.message || "Form submitted successfully!");
            setError(null);

            // Reset the form
            setFormData({ name: "", contactNo: "", email: "", message: "" });
        } catch (err) {
            setError(err.response?.data?.message || "Failed to submit the form");
            setResponseMessage("");
        }
    };



    if (loading) return <div>

    </div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <>
            <Layout>
                <div className="ContactPage">

                    <div className="container mt-5">
                        <div className="row">
                        <h2 className="text-center py-3">Our Address</h2>

                            <div className="col-md-6">
                                <div>
                                    <div>
                                        <div className="text-center bg-light text-dark rounded h-100">
                                            <div className="d-flex gap-3 align-items-center p-3 justify-content-center">
                                                <i
                                                    className="bi bi-geo-alt-fill"
                                                    style={{ color: "#11235A", fontSize: "2.3rem" }}
                                                ></i>
                                                <div>
                                                    <h5 style={{color: "#11235A"}}>Visit us at</h5>
                                                </div>
                                            </div>
                                            <p className="sm p-2">{data[0].address}</p>
                                        </div>
                                        <div className="my-3">
                                        <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.114204330678!2d79.8809088!3d23.1295005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3981b38c3ec281cf%3A0xcb58719808ed7a82!2sCrimsons%20Systems!5e0!3m2!1sen!2sin!4v1735211269051!5m2!1sen!2sin"
                                        style={{ border: 0, width: "100%" }}
                                        height="420"
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                        title="Google Map"
                                    ></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    <div className="contact-form mx-auto">

                                        <form onSubmit={handleSubmit}>
                                            <label htmlFor="name">Name</label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder="Your name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                placeholder="Your email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label htmlFor="contactNo">Contact Number</label>
                                            <input
                                                type="text"
                                                id="contactNo"
                                                name="contactNo"
                                                placeholder="Your contact number"
                                                value={formData.contactNo}
                                                onChange={handleChange}
                                                required
                                            />

                                            <label htmlFor="message">Message</label>
                                            <textarea
                                                id="message"
                                                name="message"
                                                placeholder="Write your message here..."
                                                value={formData.message}
                                                onChange={handleChange}
                                                required
                                            ></textarea>
                                            <ReCAPTCHA
                                                sitekey="6Lfq_6YqAAAAAPg_CPhQcgW_-s1eWXkWPmXbKce8"
                                                onChange={onChange}
                                            />
                                            <button type="submit" disabled={CapVal} style={{
                                                backgroundColor: CapVal ? "#000" : "#11235A"
                                            }} className="btn btn-primary mt-3">
                                                Send Message
                                            </button>
                                        </form>

                                        {responseMessage && <p className="text-success mt-3">{responseMessage}</p>}
                                        {error && <p className="text-danger mt-3">{error}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Layout>
        </>
    );
}

export default Contact;
